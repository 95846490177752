import React from 'react'
import EngineeringTemplate from 'components/templates/EngineeringTemplate/EngineeringTemplate'

export default function EngineeringFirePreventionPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Antincendio'

  const description =
    'Il decreto ministeriale 10 marzo 1998, di attuazione dell’art. 13 del D.L.vo 626/94, impone l’obbligo a <b>tutte le aziende con più di 10 dipendenti</b> di effettuare un’adeguata valutazione del rischio di incendio sui luoghi di lavoro, di redigere un piano di emergenza e di provvedere successivamente alla programmazione e attuazione delle misure preventive necessarie : installazione cartellonistica, impianti di allarme, mezzi di estinzione, esercitazioni,… . Tutte queste attività devono essere naturalmente accuratamente documentate.<br> <b>ING GROUP</b> affianca il datore di lavoro nella valutazione dell’adeguatezza della situazione aziendale in merito alla prevenzione incendi, produrre la documentazione e suggerire gli interventi necessari.<br> Progettiamo per  CPI (Certificato di Prevenzione Incendi) e curiamo tutti i rapporti con i Vigili del fuoco, sino all’ottenimento dell’autorizzazione antincendio.<br> Abbiamo curato i progetti antincendio per clienti nelle provincie di Napoli, Avellino, Caserta, Livorno, Firenze, Vicenza, Milano, Arezzo, Treviso e realizzato Piani Gestione Emergenze in circa 100 strutture distribuite su tutto il territorio nazionale.'

  return (
    <>
      <EngineeringTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
